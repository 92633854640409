export const initialState = {
  kycOpenClose: false,
  pdpPopUpOpenClose: { openClose: false, data: [], isValidPDP: false },
  miniCartOpenClose: { openClose: false, open: false },
  signInOpenClose: false,
  registerOpenClose: false,
  isLoading: false,
  currency: {},
  isSimpleLoading: false,
  cart: [],
  wishListData: [],
  gt: false,
  homeSearch: "",
  geo: "",
  customnostore: "",
  customstore: "",
  homeContent: "",
  currencyData: {},
  generalTrigger: false,
  userDataDetails: {},
  isCurrencyChanged: false,
  isPDPviewNearestHub: {},
  isServerEncounteredError: false,
  isSellerId: {
    seller_id: "",
    main_product_id: "",
    hub_id: "",
    search_result: "",
  },
  searchResultData: [],
  subUserPermission: [
    {
      permissions: [],
      main_account_id: "",
    },
  ],
  homepageOfferCout: {},
  isPDPRouteValid: true,
  newGeneralTrigger: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "GEO__LOCATION":
      return {
        ...state,
        geo: action.data,
      };
    case "GEO__CUSTOM__STORE":
      return {
        ...state,
        customstore: action.data,
      };
    case "GEO__CUSTOM__NOTSTORE":
      return {
        ...state,
        customnostore: action.data,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.value,
      };
    case "SET_IS_SIMPLE_LOADING":
      return {
        ...state,
        isSimpleLoading: action.value,
      };
    case "SET_KYC_OPEN_CLOSE":
      return {
        ...state,
        kycOpenClose: action.value,
      };
    case "SET_PDP_POPUP_OPEN_CLOSE":
      return {
        ...state,
        pdpPopUpOpenClose: { openClose: action.value, data: action.data, isValidPDP: action.isValidPDP },
      };
    case "SET_MINICART_OPEN_CLOSE":
      return {
        ...state,
        miniCartOpenClose: { open: action.open, openClose: action.value },
      };
    case "SET_SIGNIN_OPEN_CLOSE":
      return {
        ...state,
        signInOpenClose: action.value,
      };
    case "SET_REGISTER_OPEN_CLOSE":
      return {
        ...state,
        registerOpenClose: action.value,
      };
    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.data,
      };
    case "CART__DATA":
      return {
        ...state,
        cart: action.data,
      };
    case "WHISHLIST_DATA":
      return {
        ...state,
        wishListData: action.data,
      };
    case "CART__TRIGGER":
      return {
        ...state,
        gt: !state.gt,
      };
    case "SET_SEARCH":
      return {
        ...state,
        homeSearch: action.value,
      };
    case "SET_HOME_CONTENT":
      return {
        ...state,
        homeContent: action.data,
      };
    case "SET_GENERAL_TRINGGER":
      return {
        ...state,
        generalTrigger: !state?.generalTrigger,
      };
    case "SET_NEW_GENERAL_TRINGGER":
      return {
        ...state,
        newGeneralTrigger: !state?.newGeneralTrigger,
      };
    case "SET_CURRENCY_DATA":
      return {
        ...state,
        currencyData: action.data,
      };
    case "USER_DATA_DETAILS":
      return {
        ...state,
        userDataDetails: action.value,
      };
    case "SET_IS_CURRENCY_CHANGED":
      return {
        ...state,
        isCurrencyChanged: action.value,
      };
    case "SET_VIEW_PDP_NEAREST_HUB":
      return {
        ...state,
        isPDPviewNearestHub: action.data,
      };
    case "SET_IS_SELLER_ID":
      return {
        ...state,
        isSellerId: action.data,
      };
    case "SET_iS_SERVER_ENCOUNTERED_ERROR":
      return {
        ...state,
        isServerEncounteredError: action.value,
      };

    case "SET_SEARCH_RESULT_INVENTORY":
      return {
        ...state,
        searchResultData: action.data,
      };
    case "SET_SUBUSER_PERMISSION":
      return {
        ...state,
        subUserPermission: action.data,
      };
    case "SET_HOMEPAGE_OFFER_COUNT":
      return {
        ...state,
        homepageOfferCout: action.data,
      };
    case "SET_IS_ROUTE_VALID":
      return {
        ...state,
        isPDPRouteValid: action.value,
      };

    default:
      return state;
  }
};

export default reducer;
