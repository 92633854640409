let baseDomainChanges = window.location.origin;
if (baseDomainChanges === "https://www.texub.com") {
  // Production server
  module.exports = {
    baseUrl: function () {
      return "https://backend.texub.com/rest/V1/texub";
    },
    imageBaseUrl: function () {
      return "https://media.texub.com/media/";
    },
    customerTokenUrl: function () {
      return "https://backend.texub.com/rest/V1/texub/loginCustomer";
    },
    customerMeDetailUrl: function () {
      return "https://backend.texub.com/rest/V1/customers/me";
    },
    permissiondetails: function () {
      return "https://backend.texub.com/rest/V1/texub/subUserPermission";
    },
    baseUrl2: function () {
      return "https://backend.texub.com/rest/V1";
    },
    pdfDowloadUrl: function () {
      return "https://backend.texub.com";
    },
  };
}

if (baseDomainChanges === "https://uat.texub.com") {
  // Client Uat server
  module.exports = {
    baseUrl: function () {
      return "https://uat-backend.texub.com/rest/V1/texub";
    },
    imageBaseUrl: function () {
      return "https://uat-media.texub.com/media/";
    },
    customerTokenUrl: function () {
      return "https://uat-backend.texub.com/rest/V1/texub/loginCustomer";
    },
    customerMeDetailUrl: function () {
      return "https://uat-backend.texub.com/rest/V1/customers/me";
    },
    permissiondetails: function () {
      return "https://uat-backend.texub.com/rest/V1/texub/subUserPermission";
    },
    baseUrl2: function () {
      return "https://uat-backend.texub.com/rest/V1";
    },
    pdfDowloadUrl: function () {
      return "https://uat-backend.texub.com";
    },
  };
}
if (baseDomainChanges === "https://texub-frontend.uat.a2zportals.co.in") {
  // Ambab uat server
  module.exports = {
    baseUrl: function () {
      return "https://texub.uat.a2zportals.co.in/rest/V1/texub";
    },
    imageBaseUrl: function () {
      return "https://texub.uat.a2zportals.co.in/media/";
    },
    customerTokenUrl: function () {
      return "https://texub.uat.a2zportals.co.in/rest/V1/texub/loginCustomer";
    },
    customerMeDetailUrl: function () {
      return "https://texub.uat.a2zportals.co.in/rest/V1/customers/me";
    },
    permissiondetails: function () {
      return "https://texub.uat.a2zportals.co.in/rest/V1/texub/subUserPermission";
    },
    baseUrl2: function () {
      return "https://texub.uat.a2zportals.co.in/rest/V1";
    },
    pdfDowloadUrl: function () {
      return "https://texub.uat.a2zportals.co.in";
    },
  };
}
if (baseDomainChanges === "https://texub-frontend.stage.a2zportals.co.in") {
  // Ambab stage server
  module.exports = {
    baseUrl: function () {
      return "https://texub.stage.a2zportals.co.in/rest/V1/texub";
    },
    imageBaseUrl: function () {
      return "https://texub.stage.a2zportals.co.in/media/";
    },
    customerTokenUrl: function () {
      return "https://texub.stage.a2zportals.co.in/rest/V1/texub/loginCustomer";
    },
    customerMeDetailUrl: function () {
      return "https://texub.stage.a2zportals.co.in/rest/V1/customers/me";
    },
    permissiondetails: function () {
      return "https://texub.stage.a2zportals.co.in/rest/V1/texub/subUserPermission";
    },
    baseUrl2: function () {
      return "https://texub.stage.a2zportals.co.in/rest/V1";
    },
    pdfDowloadUrl: function () {
      return "https://texub.stage.a2zportals.co.in";
    },
  };
} else if (baseDomainChanges === "http://localhost:3000") {
  // local server for development
  module.exports = {
    baseUrl: function () {
      return "http://localhost:8080/rest/V1/texub";
    },
    imageBaseUrl: function () {
      return "http://localhost:8080/media/";
    },
    customerTokenUrl: function () {
      return "http://localhost:8080/rest/V1/texub/loginCustomer";
    },
    customerMeDetailUrl: function () {
      return "http://localhost:8080/rest/V1/customers/me";
    },
    permissiondetails: function () {
      return "http://localhost:8080/rest/V1/texub/subUserPermission";
    },
    baseUrl2: function () {
      return "http://localhost:8080/rest/V1";
    },
    pdfDowloadUrl: function () {
      return "http://localhost:8080";
    },
  };
}