import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import CircularProgress from "@mui/material/CircularProgress";
import { useStateValue } from "./store/state";
import "./App.scss";
import {
  GetHomeAPi,
  getSigninedUserData,
  SessionExpiredLogout,
  getMaintenanceData,
  GetCacheResponse
} from "./utilities";
import MaintananceMode from "./Pages/MaintananceMode";
import ChatBot from "./Components/Common/ChatBot";

const Header = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Components/Header")), 300)
    )
);
const Home = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/HomePage")), 300)
    )
);
const Footer = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Components/Footer")), 300)
    )
);

const Aboutus = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/AboutUs")), 300)
    )
);
const Products = lazy(
  () => import("./Pages/Products")
);
const Contactus = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/Company/Contactus")), 300)
    )
);
const Registration = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/Register")), 300)
    )
);
const FAQ = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/Resources/FAQ's/FAQ")), 300)
    )
);
const ThankYouPage = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/Register/ThankYouPage")), 300)
    )
);
const ScrollToTop = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Components/ScrollToTop")), 300)
    )
);
const ScrollToTopMain = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Components/ScrollToTop/ScrollToTopMainScroll")),
        300
      )
    )
);
//User Route
const Mycart = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/MyCart")), 300)
    )
);
const Userdetails = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Components/Userdetails")), 300)
    )
);
const SellerDashboard = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/SellerDashboard")), 300)
    )
);
const BuyerDashboard = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/BuyerDashboard")), 300)
    )
);
const SellerProfile = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/SellerProfile")), 300)
    )
);
const PendingInvoice = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/PendingInvoice/index")), 300)
    )
);
const Checkout = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/Checkout")), 300)
    )
);
const OrderSuccess = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/Checkout/OrderSuccess")), 300)
    )
);
const PaymentSuccess = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/Checkout/PaymentSuccess")), 300)
    )
);
const PaymentFail = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/Checkout/PaymentFail")), 300)
    )
);
const KYCDetails = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/KYCDetails")), 300)
    )
);
//Footer section page
const Selleradvantage = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/CMS/Services/Selleradvantage")),
        300
      )
    )
);
const Buyeradvantage = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/CMS/Services/Buyeradvantage")),
        300
      )
    )
);
// const Career = lazy(
//   () =>
//     new Promise((resolve, reject) =>
//       setTimeout(() => resolve(import("./Pages/CMS/Services/Career")), 300)
//     )
// );
const Training = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/Services/Training")), 300)
    )
);
const Termsofuse = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/CMS/Company/Termsofuse/Termsofuse")),
        300
      )
    )
);
const ProductListingPolicy = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve(
            import(
              "./Pages/CMS/Company/ProductListingPolicy/ProductListingPolicy"
            )
          ),
        300
      )
    )
);
const Blogsmain = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/CMS/Company/Blog")),
        300
      )
    )
);
const Blogs = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/Company/Blog/BlogsDetail")), 300)
    )
);
const Legal = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/Resources/Legal")), 300)
    )
);
const GobalPrivacy = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/CMS/Resources/GobalPrivacy")),
        300
      )
    )
);
const CookiesPermission = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/CMS/Resources/Cookiespermission")),
        300
      )
    )
);
//ResetPassword
const ResetPassword = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Components/ResetPassword")), 300)
    )
);

// sellonbuyontexub
const SellOnTexub = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/SellOnTexub")), 300)
    )
);
// sellonbuyontexub
const BuyOnTexub = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/BuyOnTexub")), 300)
    )
);

//popup component
const KYCformSectionLeft = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/Register/KYCform/SectionLeft")),
        300
      )
    )
);
const PDPpopUp = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/PDPpopUp")), 300)
    )
);
const SignIn = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/SignIn/SiginPopUp/SectionLeft")),
        300
      )
    )
);
const RegisterPopup = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./Pages/Register/RegisterPopup/SectionLeft")),
        300
      )
    )
);
const MiniCartList = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/MiniCart/MiniCartList")), 300)
    )
);
const SimpleBackdrop = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Components/LoaderBackDrop")), 300)
    )
);

const CheckoutInvoice = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/Checkout/CheckoutInvoice")), 300)
    )
);
// 404 page
const Found404 = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/404/Found404")), 300)
    )
);
// media-presentation
const MediaList = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./Pages/CMS/Company/MediaList")), 300)
    )
);


const App = () => {
  let userLOGdetails = JSON.parse(localStorage.getItem("userdata"));
  let isKYCFilledUser = userLOGdetails?.custom_attributes?.filter(
    (attr) => attr?.attribute_code === "kyc_status"
  )?.[0]?.value;
  let isUserLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn_auth"));
  const [maintenanceEnable, setMaintenanceEnable] = useState(true);
  const [
    {
      kycOpenClose,
      pdpPopUpOpenClose,
      registerOpenClose,
      miniCartOpenClose,
      isLoading,
      signInOpenClose,
      isServerEncounteredError,
    },
    dispatch,
  ] = useStateValue();


  const localCache = localStorage.getItem("localCache");

  useEffect(() => {
    const fetchDataEveryTwoMinutes = () => {
      if (maintenanceEnable) {
        GetCacheResponse((res) => {
          if (res?.[0]?.cache === localCache) {
            return
          }
          else {
            localStorage.setItem("localCache", res?.[0]?.cache);
            caches.keys().then((names) => {
              names?.forEach((name) => {
                caches.delete(name);
              });
            });
            window.location.reload();
          }
        });
      }
    };
    const intervalId = setInterval(fetchDataEveryTwoMinutes, 2 * 60 * 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };

  }, []);


  useEffect(() => {
    caches.keys().then((names) => {
      names?.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);
  useEffect(() => {
    getMaintenanceData((res) => {
      setMaintenanceEnable(res);
    });
  }, [isServerEncounteredError]);

  useEffect(() => {
    GetHomeAPi((res) => {
      dispatch({
        type: "SET_HOME_CONTENT",
        data: res,
      });
    });
  }, []);

  let isKYCSubmitted = JSON.parse(localStorage.getItem("userdata"));
  let SubmittedKYC = JSON.parse(localStorage.getItem("kycSubmitted"));
  useEffect(() => {
    if (isKYCSubmitted) {
      let kycValue = isKYCSubmitted?.custom_attributes?.filter(
        (itm) => itm?.attribute_code === "kyc_status"
      );
      if (SubmittedKYC === true) {
        return;
      } else {
        isKYCSubmitted?.group_id !== 1 &&
          kycValue?.[0]?.value === "0" &&
          dispatch({
            type: "SET_KYC_OPEN_CLOSE",
            value: true,
          });
      }
    }
  }, []);
  const [sellerpermission, setSellerPermission] = useState({
    inventory: false,
    orders: false,
    registerProduct: false,
  });

  useEffect(() => {
    getSigninedUserData((res) => {
      dispatch({
        type: "SET_SUBUSER_PERMISSION",
        data: res,
      });
      dispatch({
        type: "SET_GENERAL_TRINGGER",
      });
      let inventryPermission =
        res?.[0]?.permissions?.length === 0
          ? false
          : res?.[0]?.permissions?.some(
            (per) =>
              per?.value === "manage-catalog" && per?.permission_value === 0
          );
      let sellerOrderPermmision =
        res?.[0]?.permissions?.length === 0
          ? false
          : res?.[0]?.permissions?.some(
            (per) =>
              per?.value === "manage-orders" && per?.permission_value === 0
          );
      let registerProduct =
        res?.[0]?.permissions?.length === 0
          ? false
          : res?.[0]?.permissions?.some(
            (per) =>
              per?.value === "raise-product-registration" &&
              per?.permission_value === 0
          );
      setSellerPermission({
        inventory: inventryPermission,
        orders: sellerOrderPermmision,
        registerProduct: registerProduct,
      });
    });
  }, []);


  //SessionLogout Using TimeIntervals
  const time = useRef(10800);
  let log_auth = JSON.parse(localStorage.getItem("isLoggedIn_auth"));
  window.onkeyup = () => {
    localStorage.setItem("auth_time_calc", 10800);
    time.current = 10800;
  };
  window.onmousemove = () => {
    localStorage.setItem("auth_time_calc", 10800);
    time.current = 10800;
  };
  useEffect(() => {
    if (time.current && log_auth) {
      const interval = setInterval(() => {
        let t = time.current - 1;
        time.current = t;
        if (localStorage.getItem("auth_time_calc") == 1) {
          SessionExpiredLogout();
        } else {
          localStorage.setItem("auth_time_calc", t);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [log_auth]);

  return (
    <>
      {!maintenanceEnable ? (
        <MaintananceMode />
      ) : (
        <Suspense
          fallback={
            <div className="lazy_loader_content">
              <CircularProgress />
            </div>
          }
        >
          <div className="App" id="rootApp">
            <ThemeProvider theme={theme}>
              <Router>
                <ScrollToTop />
                <ScrollToTopMain />
                <ChatBot />
                <Header />
                <Userdetails />
                <Switch>
                  {/* header section */}
                  <Route exact path="/">   <Home /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country" > <Home /> <ScrollToTop /> <Footer />   </Route>
                  <Route exact path="/:country/:currency_code"> <Home /> <ScrollToTop /> <Footer />   </Route>
                  <Route exact path="/:country/:currency_code/resetpassword/:token">  <ResetPassword /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/register/:type"> <Registration /> <ScrollToTop /> <Footer />   </Route>
                  <Route path="/:country/:currency_code/products"> <Products /> <ScrollToTop /> <Footer />   </Route>
                  <Route path="/:country/:currency_code/brands"> <Products /> <ScrollToTop /> <Footer />   </Route>

                  <Route exact path="/:country/:currency_code/Aboutus">   <Aboutus /> <ScrollToTop /> <Footer /> </Route>
                  <Route exact path="/:country/:currency_code/sell-on-texub">  <SellOnTexub /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/buy-on-texub"> <BuyOnTexub /> <ScrollToTop /> <Footer />   </Route>
                  <Route exact path="/:country/:currency_code/media-presentation"> <MediaList /> <ScrollToTop /> <Footer />   </Route>

                  {/* Footer section */}
                  <Route exact path="/:country/:currency_code/legal">  <Legal /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/global-privacy/:id" > <GobalPrivacy /> <ScrollToTop /> <Footer />   </Route>
                  <Route exact path="/:country/:currency_code/cookies-permission/:id"> <CookiesPermission /> <ScrollToTop /> <Footer />   </Route>
                  <Route exact path="/:country/:currency_code/Contactus">  <Contactus /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/termsofuse/:id"> <Termsofuse /> <ScrollToTop /> <Footer />   </Route>
                  <Route exact path="/:country/:currency_code/productlistingpolicy/:id">   <ProductListingPolicy /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/training">  <Training /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/seller_advantage">  <Selleradvantage /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/buyer_advantage" >  <Buyeradvantage /> <ScrollToTop /> <Footer />   </Route>
                  {/* <Route exact path="/:country/:currency_code/career">   <Career /> <ScrollToTop /> <Footer />  </Route> */}
                  <Route exact path="/:country/:currency_code/market-updates"><Blogsmain /> <ScrollToTop /> <Footer />    </Route>
                  <Route exact path="/:country/:currency_code/market-updates/:id">   <Blogs /> <ScrollToTop /> <Footer />  </Route>
                  <Route exact path="/:country/:currency_code/faqs">  <FAQ /> <ScrollToTop /> <Footer />  </Route>


                  {/* user section */}
                  {sellerpermission?.inventory ? <Route path="/:country/:currency_code/sellerdashboard/inventory" exact > <Found404 />  <Footer /><ScrollToTop /> </Route>
                    : <Route path="/:country/:currency_code/sellerdashboard/:currenttab" exact > {userLOGdetails?.group_id === 6 && isKYCFilledUser == 2 ? <> {" "} <SellerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>} </Route>
                  }
                  {sellerpermission?.inventory ? <Route path="/:country/:currency_code/sellerdashboard/smart-recommendation" exact ><Found404 />  <Footer /><ScrollToTop />  </Route>
                    : <Route path="/:country/:currency_code/sellerdashboard/:currenttab" exact >{userLOGdetails?.group_id === 6 && isKYCFilledUser == 2 ? <> {" "} <SellerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>} </Route>
                  }
                  {sellerpermission?.orders ? <Route path="/:country/:currency_code/sellerdashboard/orders" exact ><Found404 />  <Footer /><ScrollToTop /> </Route>
                    : <Route path="/:country/:currency_code/sellerdashboard/:currenttab" exact > {userLOGdetails?.group_id === 6 && isKYCFilledUser == 2 ? <> {" "} <SellerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}</Route>
                  }
                  {sellerpermission?.orders ? <Route path="/:country/:currency_code/sellerdashboard/directenquiries" exact > <Found404 />  <Footer /><ScrollToTop />  </Route>
                    : <Route path="/:country/:currency_code/sellerdashboard/:currenttab" exact > {userLOGdetails?.group_id === 6 && isKYCFilledUser == 2 ? <> {" "} <SellerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}</Route>
                  }
                  {sellerpermission?.registerProduct ? <Route path="/:country/:currency_code/sellerdashboard/registerproduct" exact > <Found404 />  <Footer /><ScrollToTop /> </Route>
                    : <Route path="/:country/:currency_code/sellerdashboard/:currenttab" exact >{userLOGdetails?.group_id === 6 && isKYCFilledUser == 2 ? <> {" "} <SellerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>} </Route>
                  }
                  {/* <Route path="/:country/:currency_code/sellerdashboard/:currenttab" element={ userLOGdetails?.group_id===6 && isKYCFilledUser==2?<> {" "} <SellerDashboard /> <ScrollToTop /> <Footer />{" "} </>:<> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </> } exact /> */}

                  <Route exact path="/:country/:currency_code/sellerdashboard/:currenttab/:id">  {userLOGdetails?.group_id === 6 && isKYCFilledUser == 2 ? <> {" "} <SellerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/buyerdashboard/:currenttab">   {userLOGdetails?.group_id === 5 && isKYCFilledUser == 2 ? <> {" "} <BuyerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>} </Route>
                  <Route exact path="/:country/:currency_code/buyerdashboard/:currenttab/:id">  {userLOGdetails?.group_id === 5 && isKYCFilledUser == 2 ? <> {" "} <BuyerDashboard /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/sellerprofile/:id">  {<> {" "} <SellerProfile /> <ScrollToTop /> <Footer />{" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/proforma-invoice/:qid">  {userLOGdetails?.group_id === 5 ? <> {" "} <PendingInvoice /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/checkout/:quoteid"> {userLOGdetails?.group_id === 5 ? <> {" "} <Checkout /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}   </Route>
                  <Route exact path="/:country/:currency_code/ordersuccess/:id">  {userLOGdetails?.group_id === 5 ? <> {" "} <OrderSuccess /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/paymentsuccess">  {userLOGdetails?.group_id === 5 ? <> {" "} <PaymentSuccess /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/paymentfail">  {userLOGdetails?.group_id === 5 ? <> {" "} <PaymentFail /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/mycart">  {userLOGdetails?.group_id === 5 ? <> {" "} <Mycart /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/thankyou/:type">  {isUserLoggedIn === true ? <> {" "} <ThankYouPage /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>
                  <Route exact path="/:country/:currency_code/kycdetails">   {isUserLoggedIn === true ? <> {" "} <KYCDetails /> <ScrollToTop /> <Footer />{" "} </> : <> {" "} <Found404 />  <Footer /><ScrollToTop /> {" "} </>}  </Route>


                  {/* Invoice download */}
                  <Route exact path="/:country/:currency_code/checkout-invoice/:quoteid"> {userLOGdetails?.group_id === 5 ? <> <CheckoutInvoice /> <ScrollToTop /> </> : <> <Found404 />  <Footer /><ScrollToTop /> </>}   </Route>
                  <Route path="/:country/:currency_code"> <Products /> <ScrollToTop /> <Footer />   </Route>
                  <Route exact path='*' >  <Found404 />  </Route>

                </Switch>

                {/* Popup component using context api */}
                {kycOpenClose && <KYCformSectionLeft />}
                {pdpPopUpOpenClose?.openClose && <PDPpopUp />}
                {signInOpenClose && <SignIn />}
                {registerOpenClose && <RegisterPopup />}
                {miniCartOpenClose?.openClose && <MiniCartList />}
                {isLoading && <SimpleBackdrop />}
              </Router>
            </ThemeProvider>
          </div>
        </Suspense>
      )}
    </>
  );
};

export default App;
